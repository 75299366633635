

declare var Swiper: any;

function ourTechSwiper(){
	var swiper = new Swiper('.our-tech .swiper-container', {
		slidesPerView: 3,
		centeredSlides: true,
		spaceBetween: 20,
		loop: true,
		pagination:{
			el: ".our-tech .swiper-pagination",
			clickable: true,
		},
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
	});
}


var swiper2 = new Swiper('.mattress-recommend__wrapper .right-recommend .swiper-container', {
	slidesPerView: 1,

	loop: true,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});

var swiper3 = new Swiper(".swiper-btn__nav .mySwiper", {
	spaceBetween: 10,
	slidesPerView: 4,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});

function swiperDetailProducts(){
	var swiper4 = new Swiper(".details-infor__wrapper .mySwiper2", {
		zoom: true,
		spaceBetween: 10,
	
		thumbs: {
			swiper: swiper3,
		},
	});
}

function swiperWholesaleService(){
	var nextBtn = <HTMLElement> document.querySelector('.wholesale .wholesale-service-left .swiper-button-next');
	var prevBtn = <HTMLElement> document.querySelector('.wholesale .wholesale-service-left .swiper-button-prev');
	if (nextBtn && prevBtn){
		var blockSwiper = document.querySelector('.wholesale .wholesale-service-left .images');
		var titleSwiper = document.querySelector('.wholesale .wholesale-service-left .title');
		if (blockSwiper && titleSwiper){
			var heightSwiper = blockSwiper.clientHeight;
			var heightTitle = titleSwiper.clientHeight;
			var height = (heightSwiper / 2) + heightTitle;
			nextBtn.style.top = height + "px";	
			prevBtn.style.top = height + "px";	
		}
	}

	new Swiper('.wholesale .wholesale-service-left .images .swiper-container', {
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		pagination: {
			el: ".wholesale .wholesale-service-left .swiper-pagination",
			clickable: true,
		},
	})
}

// Thêm mũi tên sau mỗi button có query .btn .btn-arrow
function fBtnArrow() {
	var btnArrows = document.querySelectorAll('.btn .btn-arrow');
	btnArrows.forEach(function (btn) {
		btn.innerHTML += ' ' + `<svg xmlns="http://www.w3.org/2000/svg" width="12.922" height="12.791" viewBox="0 0 12.922 12.791"><path id="Path_126" data-name="Path 126" d="M0,0,5.188,5.33S10.377-.136,10.377,0" transform="translate(6.592 11.377) rotate(-90)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path id="Path_127" data-name="Path 127" d="M0,0,5.188,5.33S10.377-.136,10.377,0" transform="translate(1.411 11.377) rotate(-90)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>`;
	});
}

// active icon feature
function activeIconFeatures() {
	if (document.querySelector('.featured')) {
		var icons = document.querySelectorAll('.featured .icons .icons-item');
		icons.forEach(function (icon) {
			icon.addEventListener('click', function () {
				console.log([icon])
				var widthSpan = 220;
				var iconHTML = <HTMLElement> icon;
				var iconParent = iconHTML.offsetParent;
				console.log(iconHTML.offsetLeft + 220);
				console.log(iconParent.clientWidth);

				if (iconHTML.offsetLeft + 220 >= iconParent.clientWidth){
					if (icon.classList.contains('active-right') || icon.classList.contains('active')){
						icon.classList.remove('active');
						icon.classList.remove('active-right');
					}
					else{
						icons.forEach(function (e) {
							e.classList.remove('active');
							e.classList.remove('active-right');
						})
		
						icon.classList.add('active-right');
					}
				}
				else{
					if (icon.classList.contains('active')){
						icon.classList.remove('active');
					}
					else{
						icons.forEach(function (e) {
							e.classList.remove('active');
						})
		
						icon.classList.add('active');
					}
				}
			})
		})
	}
}

function activeCartShop(evt: any) {
	var cartShop = document.querySelector('.header-top-section-right .shop-cart');
	if (cartShop) {
		var evtTarget = evt.target;
		// Kiem tra co click vao cartshop ko? Neu co thi ko lam gi` ca
		do {
			if (evtTarget == cartShop) {
				var cartShopIcon = document.querySelector('.header-top-section-right .shop-cart__icon');
				if (!cartShopIcon) {
					return;
				}
				return;
			}
			evtTarget = evtTarget.parentNode;
		} while (evtTarget);

		// Neu ko thi se remove class active
		var cartShopIcon = document.querySelector('.header-top-section-right .shop-cart__icon');
		cartShopIcon.classList.remove('active');
	}
}

function activeHamberger(evt: any) {
	var hamberger = document.querySelector('.header-top-section-right__ham');
	if (hamberger) {
		var evtTarget = evt.target;
		// Kiem tra co click vao hamberger ko? Neu co thi ko lam gi` ca
		do {
			if (evtTarget == hamberger) {
				var hambergerImg = document.querySelector('.header-top-section-right__ham .hamberger__img');
				if (!hambergerImg) {
					return;
				}
				return;
			}
			evtTarget = evtTarget.parentNode;
		} while (evtTarget);

		// Neu ko thi se remove class active
		var hambergerImg = document.querySelector('.header-top-section-right__ham .hamberger__img');
		hambergerImg.classList.remove('active');
	}
}

function activeUser(evt: any) {
	var userBtn = document.querySelector('.header-top-section-right .user');
	if (userBtn) {
		var evtTarget = evt.target;
		do {
			if (evtTarget == userBtn) {
				var userBtnImg = document.querySelector('.header-top-section-right .user__img');
				if (!userBtnImg) {
					return;
				}
				return;
			}
			evtTarget = evtTarget.parentNode;
		} while (evtTarget);

		var userBtnImg = document.querySelector('.header-top-section-right .user__img');
		userBtnImg.classList.remove('active');
	}
}

function activeFind(evt: any) {
	var findBtn = document.querySelector('.header-top-section-right .find');
	if (findBtn) {
		var evtTarget = evt.target;
		do {
			if (evtTarget == findBtn) {
				var findBtnImg = document.querySelector('.header-top-section-right .find__img');
				if (!findBtnImg) {
					return;
				}
				return;
			}
			evtTarget = evtTarget.parentNode;
		} while (evtTarget);

		var findBtnImg = document.querySelector('.header-top-section-right .find__img');
		findBtnImg.classList.remove('active');
	}
}

function toggleHeader() {
	// toggle CartShop
	var cartShop = document.querySelector('.header-top-section-right .shop-cart__icon');
	cartShop.addEventListener('click', function () {
		cartShop.classList.toggle('active');
	})

	// close Cart Shop
	var closeShopCart = document.querySelector('.header-top-section-right .shop-cart-box--close');
	if (closeShopCart) {
		closeShopCart.addEventListener('click', function () {
			var cartShop = document.querySelector('.header-top-section-right .shop-cart__icon');
			if (cartShop) {
				cartShop.classList.remove('active');
			}
		})
	}

	// toggle Hamberger
	var hamberger = document.querySelector('.header-top-section-right__ham .hamberger__img');
	if (hamberger) {
		hamberger.addEventListener('click', function () {
			hamberger.classList.toggle('active');
		});
	}

	// toggle User
	var userBtn = document.querySelector('.header_wapper .user__img');
	if (userBtn) {
		userBtn.addEventListener('click', function (evt) {
			userBtn.classList.toggle('active');
		})
	}

	// toggle search
	var findBtn = document.querySelector('.header_wapper .find__img');
	if (findBtn) {
		findBtn.addEventListener('click', function (evt) {
			findBtn.classList.toggle('active');
		})
	}
}

function headerUserHandle() {
	if (document.querySelector('.header_wapper')) {
		document.addEventListener('click', function (evt) {
			activeUser(evt);
			activeHamberger(evt);
			activeCartShop(evt);
			activeFind(evt);
		})
		toggleHeader();

		var boxes = document.querySelectorAll('.hamberger-box__item');
		if (boxes) {
			boxes.forEach(function (box) {
				box.addEventListener('click', function () {
					if (box.classList.contains('active')) {
						box.classList.remove('active');
					}
					else {
						boxes.forEach(function (box) {
							box.classList.remove('active');
						})
						box.classList.add('active');
					}
				})
			})
		}

		// close hamberger
		var outMenuBar = document.querySelector('.hamberger');
		if (outMenuBar) {
			outMenuBar.addEventListener('click', function () {
				document.querySelector('.header-top-section-right__ham').classList.remove('active');
			})
		}

		// // number cart
		var numberCart = <HTMLElement>document.querySelector('.header-top-section-right .shop-cart__icon');
		if (numberCart) {
			var value = numberCart.getAttribute('data-number');

			if (typeof (parseInt(value)) === typeof (1)) {
				if (parseInt(value) <= 0) {
					if (!numberCart.classList.contains('after--hidden')) {
						numberCart.classList.add('after--hidden');
					}
				}
			}
			else {
				if (!numberCart.classList.contains('after--hidden')) {
					numberCart.classList.add('after--hidden');
				}
			}
		}


		// handle click link
		var loginFormUser = document.getElementById('header-user');
		var loginFormDealer = document.getElementById('header-dealer');
		var registerFormUser = document.querySelector('.form-content__register--user');
		var registerFormDealer = document.querySelector('.form-content__register--dealer');
		var loginFormNav = document.querySelectorAll('.form-content__register--nav');
		var forgotFormNav = document.querySelectorAll('.form-content__forgot--nav');
		var shopNowButton = document.querySelectorAll('.shop-now__button');
		var shopCartButton = document.querySelector('.shop-cart-box__button');

		// login
		if (loginFormUser) {
			loginFormUser.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-login__wrapper');
			})
		}
		if (loginFormDealer) {
			loginFormDealer.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-login__wrapper');
			})
		}

		// register
		if (registerFormUser) {
			registerFormUser.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-user__wrapper');
			})
		}

		if (registerFormDealer) {
			registerFormDealer.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-dealer__wrapper');
			})
		}

		// nav login
		loginFormNav.forEach(function (nav) {
			// console.log(loginFormNav);
			nav.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-login__wrapper');
			})
		})

		// forgot password
		forgotFormNav.forEach(function (forget) {
			forget.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-forgot__wrapper');
			})
		})

		// shop cart
		if (shopCartButton) {
			shopCartButton.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-custom__wrapper');
			})
		}

		shopNowButton.forEach(function (sbtn) {
			sbtn.addEventListener('click', function (e) {
				e.preventDefault();
				removeActiveForm();
				addActiveForm('form-custom__wrapper');
			})
		})

		// Clicked close form
		var closePopups = document.querySelectorAll('.loginform__close');
		if (closePopups) {
			closePopups.forEach(function (pop) {
				pop.addEventListener('click', function (e) {
					removeActiveForm();
				})
			})
		}
	}
}

function submitSearch() {
	var smSearch = document.getElementById('formSearch');
	smSearch.addEventListener('submit', function (e) {
		e.preventDefault();
	})
}

function addActiveForm(queryStringId: any) {
	var active = document.getElementById(queryStringId);
	if (active) {
		if (!active.classList.contains('active')) {
			active.classList.add('active');
		}
	}

	var body = document.querySelector('body');
	if (body) {
		body.style.height = "100vh";
		body.style.overflow = "hidden";
	}
}

function removeActiveForm() {
	var login = document.getElementById('form-login__wrapper');
	var registerUser = document.getElementById('form-user__wrapper');
	var registerDealer = document.getElementById('form-dealer__wrapper');
	var custom = document.getElementById('form-custom__wrapper');
	var forgot = document.getElementById('form-forgot__wrapper');

	if (login) {
		if (login.classList.contains('active')) {
			login.classList.remove('active');
		}
	} if (registerUser) {
		if (registerUser.classList.contains('active')) {
			registerUser.classList.remove('active');
		}
	} if (registerDealer) {
		if (registerDealer.classList.contains('active')) {
			registerDealer.classList.remove('active');
		}
	} if (custom) {
		if (custom.classList.contains('active')) {
			custom.classList.remove('active');
		}
	} if (forgot) {
		if (forgot.classList.contains('active')) {
			forgot.classList.remove('active');
		}
	}

	var body = document.querySelector('body');
	if (body) {
		body.style.height = "";
		body.style.overflow = "";
	}
}

function footerHandle() {
	if (document.querySelector('.footer__wrapper')) {
		// change input
		var inputEmail = document.getElementById('emailFooter');
		inputEmail.addEventListener('change', function () {
			var info = document.getElementById('formEmailFooterInfo');
			info.innerHTML = "";
			info.classList.remove('success');
			info.classList.remove('error');
		})

		// submit form email
		var formEmail = document.getElementById('formEmailFooter');
		formEmail.addEventListener('submit', function (e) {
			e.preventDefault();
			var email = <HTMLInputElement>document.getElementById('emailFooter');
			var data = { email: email.value };

			// Get api
			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function () {
				var info = document.getElementById('formEmailFooterInfo');
				if (this.readyState == 4 && this.status == 200) {
					info.classList.add('success');
					info.innerHTML = this.responseText;
				}
				else {
					info.classList.add('error');
					info.innerHTML = this.responseText;
					return;
				}
			};
			// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
			xhttp.open("POST", '/post-email-footer', true);
			xhttp.send(JSON.stringify(data));
		})
	}
}


// swiper 5 & 6 in fDetailProducts
function fDetailProducts() {
	if (document.querySelector('.details-infor__wrapper')) {
		// effect star
		var starSection = document.querySelectorAll('.detail-infor__content .rate-section');
		starSection.forEach(function (item) {
			var child = item.lastElementChild;

			if (child) {
				child = child.children[0];

				if (child) {
					child.children[0];
					if (child) {
						var getStar = child.getAttribute('data-star');
						var stars = child.children;

						for (var i = 1; i < stars.length; i++) {
							if (i === parseInt(getStar)) {
								for (var j = i; j > 0; j--) {
									child.children[j - 1].classList.add('active');
								}
							}
						}
					}
				}
			}
		})

		//create swiper information product
		var swiper5 = new Swiper(`.details-infor__wrapper .product-feature-warranty .product-selected > .product-selected--1 > .swiper-container`, {
			// spaceBetween: 15,
			slidesPerView: 1,
		});

		var swiper6 = new Swiper(`.details-infor__wrapper .product-feature-warranty .product-selected > .product-selected--1 > .feature-icons > .swiper-container`, {
			spaceBetween: 15,
			slidesPerView: 5,
		});

		// review
		var review = document.querySelectorAll('.details-review__wrapper .details-review__form .items-star .star');
		if (review) {
			review.forEach((element, index) => element.addEventListener('mouseover', () => {
				review.forEach(r => r.classList.remove('active'));
				for (var i = index; i >= 0; i--) {
					review[i].classList.add('active');
				}
			}))

			review.forEach((element, index) => element.addEventListener('click', () => {
				review.forEach(r => { r.classList.remove('active'); r.classList.remove('selected') });
				review[index].classList.add('selected');

				var star = <HTMLInputElement>document.getElementById('reviewStar');
				star.value = "" + (index + 1);
				for (var i = index; i >= 0; i--) {
					review[i].classList.add('active');
				}
			}))
		}

		// comment star
		var commentRates = document.querySelectorAll('.details-review__wrapper .details-user__comment .comment-items .items-star');
		if (commentRates) {
			commentRates.forEach(function (rate) {
				var value = parseInt(rate.getAttribute('data-selected'));
				for (var i = value - 1; i >= 0; i--) {
					rate.children[i].classList.add('active');
				}
			})
		}

		// more comment
		var commentMore = document.querySelector('.details-review__wrapper .details-user__comment .comment__more--btn');
		var commentReviews = document.querySelector('.details-review__wrapper .details-user__comment .comment__reviews');
		if (commentMore && commentReviews) {
			// visible là đang hiện 4 mục
			var visibleComment = commentMore.getAttribute('data-comment-visibled');
			
			// tổng số review
			var reviewComment = commentReviews.getAttribute('data-comment-reviews');

			if (visibleComment < reviewComment) {
				var cmtActive = document.querySelector('.details-review__wrapper .details-user__comment .comment__more');
				cmtActive.classList.add('active');

				commentMore.addEventListener('click', function () {
					// get api
					var xhttp = new XMLHttpRequest();
					xhttp.onreadystatechange = function () {
						if (this.readyState == 4 && this.status == 200) {
							var blockText = document.createElement('div');
							blockText.innerHTML = this.responseText;
							var iVisible = parseInt(visibleComment);
							alert(blockText.children.length);
							if (iVisible && blockText.children) {
								visibleComment = (iVisible + blockText.children.length) + "";
								commentMore.setAttribute('data-comment-visibled', visibleComment);

								if (visibleComment >= reviewComment) {
									cmtActive.classList.remove('active');
								}

								var wrapper = document.querySelector('.details-review__wrapper .details-user__comment .comment__section');
								wrapper.innerHTML += blockText.innerHTML;
							}
							else {
								console.log('Error!!!');
							}
						}
						else {
							alert(`not found comment ${parseInt(visibleComment) + 1}`);
							return;
						}
					};
					// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
					xhttp.open("GET", `/get-comment/${parseInt(visibleComment) + 1}`, true);
					xhttp.send();
				})
			}
		}
	}
}


function toggleinput() {
	if (!document.querySelector('.faq-content__wrapper')) {
		return;
	}
	var contentList = document.querySelectorAll(".content-right__list .faq-right__list");
	if (!contentList) {
		return;
	}
	contentList.forEach(item => item.addEventListener('click', () => {
		// xoa active
		if (item.classList.contains('active')) {
			item.classList.remove('active');
		}
		else {
			contentList.forEach(function (e) {
				e.classList.remove("active");
			})

			// them active vao item da click
			item.classList.add("active");
		}

		// var reScroll = document.getElementById("scrollToContent");
		// reScroll.scrollIntoView();
	}))

	var listCate = document.querySelectorAll('.faq-content__wrapper .faq-content .faq-left__list');
	if (!listCate) {
		return;
	}
	listCate.forEach(function (itemCate) {
		itemCate.addEventListener('click', function () {
			var value = itemCate.getAttribute('data-categories');

			// Get api
			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function () {
				if (this.readyState == 4 && this.status == 200) {
					var wrapper = document.querySelector('.faq-content__wrapper .faq-content__right .faq-right__items');
					wrapper.innerHTML = this.responseText;
				}
				else {
					console.log(`not found ${value}`);
					return;
				}
			};
			// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
			xhttp.open("GET", `/get-cate-faq/${value}`, true);
			xhttp.send();

			// active
			if (itemCate.classList.contains('active')) {
				itemCate.classList.remove('active');
			}
			else {
				listCate.forEach(function (e) {
					e.classList.remove("active");
				})

				// them active vao item da click
				itemCate.classList.add("active");
			}
		})
	})
}


// var swiper6 = new Swiper(".details-infor__wrapper .product-feature-warranty .product-selected--1 .swiper-container", {
// 	// spaceBetween: 15,
// 	slidesPerView: 1,
// });
// var swiper6 = new Swiper(".details-infor__wrapper .product-feature-warranty .product-selected--2 .swiper-container", {
// 	// spaceBetween: 15,
// 	slidesPerView: 1,

// });
// var swiper5 = new Swiper(".details-infor__wrapper .product-feature-warranty .product-selected--1 .feature-icons .swiper-container", {
// 	spaceBetween: 15,
// 	slidesPerView: 5,
// });
// function fLoadSwiperWarranty(){
// 	alert(1);
// 	// const product_details = document.querySelectorAll(".details-infor__wrapper .product-feature-warranty .nav-feature .product-feature")
// 	// console.log(product_details);
// 	// product_details.forEach(function(e, index){
// 	// 	// var swiper6 = new Swiper(".details-infor__wrapper .product-feature-warranty .product-selected--1 .swiper-container", {
// 	// 	// 	// spaceBetween: 15,
// 	// 	// 	slidesPerView: 1,
// 	// 	// });
// 	// 	console.log(index);
// 	// 	var swiper7 = new Swiper(`.details-infor__wrapper .product-feature-warranty .product-selected > .product-selected--${index + 1} > .feature-icons > .swiper-container`, {
// 	// 		spaceBetween: 15,
// 	// 		slidesPerView: 5,
// 	// 	});
// 	// })
// }



const showContentDetails = () => {
	const list = document.querySelectorAll(".details-infor__wrapper .feature-details .dropdown-feature")

	list.forEach(element => element.addEventListener('click', () => {
		const caret1 = document.querySelectorAll(".details-infor__wrapper .feature-details .dropdown-feature .active-caret")

		const listactive = document.querySelector(".details-infor__wrapper .feature-details .dropdown-feature .active")
		var child = element.children

		for (var i = 0; i < child.length; i++) {
			var caret = child[i].children
			if (caret1 === null) {
				caret[0].classList.add("active-caret")
			}
			else {
				caret1.forEach(element => element.classList.remove("active-caret"))
				caret[0].classList.add("active-caret")
			}

		}

		if (listactive === null) {
			child[1].classList.add("active")
		} else {
			child[1].classList.add("active")
			listactive.classList.remove("active")

		}

	}))
}

const navSelect = () => {
	const mattress3 = document.querySelectorAll(".mattress-recommend__wrapper .options-recommend .options")
	const mattress2 = document.querySelectorAll(".mattress-collections__wrapper .mattress-collections .collections-section .options")
	const product_details1 = document.querySelectorAll(".details-infor__wrapper .product-feature-warranty .nav-feature .product-feature")
	const product_details2 = document.querySelectorAll(".details-function-benefit__wrapper .details-nav .nav-function .product-feature")
	const dealer_digital = document.querySelectorAll(".dealer-navigation-digital__wrapper .dealer-navigation-digital a")
	const review = document.querySelectorAll(".details-review__wrapper .user-comment-section")

	mattress2.forEach(element => element.addEventListener('click', () => {
		const active_item = document.querySelector('.mattress-collections__wrapper .mattress-collections .collections-section .active');
		active_item.classList.remove('active')
		element.classList.add("active")
	}))

	mattress3.forEach(element => element.addEventListener('click', () => {
		const active_item = document.querySelector('.mattress-recommend__wrapper .options-recommend .active');
		active_item.classList.remove('active')
		element.classList.add("active")
	}))

	product_details1.forEach((element, index) => element.addEventListener('click', () => {
		const active_item = document.querySelector('.details-infor__wrapper .product-feature-warranty .nav-feature .active');
		active_item.classList.remove('active')
		element.classList.add("active")

		const activeProduct = document.querySelectorAll('.details-infor__wrapper .product-feature-warranty .product-selected .product-selected--value');
		activeProduct.forEach(product => product.classList.remove('active'));
		activeProduct[index].classList.add('active');

		new Swiper(`.details-infor__wrapper .product-feature-warranty .product-selected > .product-selected--${index + 1} > .swiper-container`, {
			// spaceBetween: 15,
			slidesPerView: 1,
		});

		new Swiper(`.details-infor__wrapper .product-feature-warranty .product-selected > .product-selected--${index + 1} > .feature-icons > .swiper-container`, {
			spaceBetween: 15,
			slidesPerView: 5,
		});
	}))

	product_details2.forEach((element, index) => element.addEventListener('click', () => {
		const active_item = document.querySelector('.details-function-benefit__wrapper .details-nav .nav-function .active');
		active_item.classList.remove('active')
		element.classList.add("active")

		const contents = document.querySelectorAll('.details-function-benefit .content--value');
		contents.forEach(function (content) {
			content.classList.remove('active');
		})
		contents[index].classList.add('active');
	}))

	dealer_digital.forEach(element => element.addEventListener('click', () => {
		const active_item = document.querySelector(".dealer-navigation-digital__wrapper .dealer-navigation-digital .active");
		active_item.classList.remove('active')
		element.classList.add("active")
	}))

	review.forEach(element => element.children[0].addEventListener('click', () => {
		// const active_item = document.querySelector(".details-review__wrapper .uesr-comment");
		// active_item.classList.remove('active')
		if (element.children[1] === null) {

		} else {
			element.children[1].classList.toggle("active")
		}

		// element.classList.add("active")
	}))

}

// const changeActive = (active__item,element) =>{
// 		// const active_item = document.querySelector('.mattress-recommend__wrapper .left-recommend .options-recommend a.active');
// 		active__item.classList.remove('active')
// 		element.classList.add("active")
// }



// const showSubMenuMobile = () => {
// const btn = document.querySelector('.btn-menu');
//   const btn_close = document.querySelector('.mobile-navbar-close');
// const overlayMennu = document.querySelector('.mobile-wapper');
// // SHOW MAIN MENU !!!
// if (btn) {
// 	btn.addEventListener('click', (e) => {
// 		document.querySelector('body').classList.toggle('disabled');
// 		btn.classList.add('show');
// 		// mainoverlay.classList.add('side-nav');
//           overlayMennu.classList.add('show-nav');

// 		// CLOSE ALL SUB MENU

// 	});
// }if(btn_close){
// 	btn_close.addEventListener('click', (e) => {
// 		document.querySelector('body').classList.remove('disabled');
// 		btn.classList.remove('show');
// 		// mainoverlay.classList.remove('show__overlay');
//           overlayMennu.classList.remove('show-nav');

// 		// CLOSE ALL SUB MENU

// 	});
//   }else {
// 	console.log(`Không tồn tại element :=> .navBarHamburger__wrapper`);
// }
// }

var endCheck = function () {
	var checkBox = document.querySelectorAll(".mobile-nav-sub__link");
	checkBox.forEach(box => {
		box.addEventListener('click', function () {
			Uncheck();
		})
	});
}

function Uncheck() {
	var checkedBox = <HTMLInputElement>document.getElementById("mobile__checkbox");
	checkedBox.checked = false;
}

// About us story
var swiperPartner = new Swiper(".about-story-partner__wrapper .about-story-partner__list", {
	slidesPerView: 5,
	spaceBetween: 150,
	slidesPerGroup: 1,
	loop: true,


	navigation: {
		nextEl: ".about-story-partner__wrapper .swiper-button-next",
		prevEl: ".about-story-partner__wrapper .swiper-button-prev",
	},
	breakpoints: {
		120: {
			slidesPerView: 1,
			spaceBetween: 50,
		},
		320: {
			slidesPerView: 1,
			spaceBetween: 50,
		},
		425: {
			slidesPerView: 2,
			spaceBetween: 50,
		},
		600: {
			slidesPerView: 3,
			spaceBetween: 50,
		},
		768: {
			slidesPerView: 4,
			spaceBetween: 50,
		},
		1024: {
			slidesPerView: 5,
			spaceBetween: 70,
		},
		1440: {
			slidesPerView: 5,
			spaceBetween: 120,
		}
	}
});

// About us media
// var swiperReleases = new Swiper(".about-media-releases .releases-pagination .releases-pagination-swiper", {
// 	slidesPerView: 5,
// 	spaceBetween: 30,
// 	slidesPerGroup: 1,

// 	navigation: {
// 		nextEl: ".about-media-releases .releases-pagination .swiper-button-next",
// 		prevEl: ".about-media-releases .releases-pagination .swiper-button-prev",
// 	}
// });

var clickPaginationReleases = function () {
	if (document.querySelector('.about-media-releases__wrapper')) {
		var reBox = document.querySelectorAll(".about-media-releases__content .releases-box .releases-box-item");
		var pageNumber = 4;
		var page = Math.ceil(reBox.length / pageNumber);
		var iNumber = 1;

		for (var i = 0; i < reBox.length; i++) {
			if (Math.floor(i / pageNumber + 1) == iNumber) {
				reBox[i].classList.remove("hidden");
			}
			else {
				reBox[i].classList.add("hidden");
			}
		}

		var addPagination = document.querySelector('.about-media-releases__content');
		if (addPagination === null) {
			return;
		}

		var rePagination = document.createElement('div');
		rePagination.classList.add('releases-pagination');

		var reSwiper = document.createElement('div');
		reSwiper.classList.add('releases-pagination-swiper');
		reSwiper.classList.add('swiper-container');

		var reWrapper = document.createElement('div');
		reWrapper.classList.add('swiper-wrapper');

		for (var i = 0; i < page; i++) {
			reWrapper.innerHTML += `<div class="releases-pagination__button swiper-slide" data-releases="${i + 1}"><span>${i + 1}<span></div>`;
		}

		var reNext = document.createElement('div');
		var rePrev = document.createElement('div');
		reNext.classList.add('swiper-button-next');
		rePrev.classList.add('swiper-button-prev');
		reNext.classList.add('releases-pagination__next');
		rePrev.classList.add('releases-pagination__prev');


		reSwiper.appendChild(reWrapper);
		reSwiper.appendChild(reNext);
		reSwiper.appendChild(rePrev);

		rePagination.appendChild(reSwiper);

		addPagination.appendChild(rePagination);

		var swiperReleases = new Swiper(".about-media-releases .releases-pagination .releases-pagination-swiper", {
			slidesPerView: 4,
			spaceBetween: 30,
			slidesPerGroup: 1,
			breakpoints: {
				320: {
					spaceBetween: 25,
				},
				640: {
					spaceBetween: 30,
				}
			},
			navigation: {
				nextEl: ".about-media-releases .releases-pagination .swiper-button-next",
				prevEl: ".about-media-releases .releases-pagination .swiper-button-prev",
			}
		});

		document.querySelector(".about-media-releases .releases-pagination .releases-pagination-swiper .releases-pagination__button").classList.add("active");
		var pagination = document.querySelectorAll(".about-media-releases .releases-pagination .releases-pagination__button");
		pagination.forEach(function (p) {
			p.addEventListener("click", function (e) {
				pagination.forEach(function (item) {
					if (item.classList.contains("active")) {
						item.classList.remove("active");
					}
				})
				p.classList.add('active');

				var number = p.getAttribute("data-releases");
				var iNumber = parseInt(number);
				var reBox = document.querySelectorAll(".about-media-releases__content .releases-box .releases-box-item");

				var pageNumber = 4;

				for (var i = 0; i < reBox.length; i++) {
					if (Math.floor(i / pageNumber + 1) == iNumber) {
						reBox[i].classList.remove("hidden");
					}
					else {
						reBox[i].classList.add("hidden");
					}
				}
				// var reScroll = document.getElementById("scrollToReleases");
				// reScroll.scrollIntoView();
			})
		})
	}
}

var clickFancybox = function () {
	if (document.querySelector('.about-media-video__wrapper')) {
		// about media video
		var swiperVideo = new Swiper(".about-media-video__wrapper .releases-pagination .releases-pagination-swiper", {
			slidesPerView: 4,
			spaceBetween: 30,
			slidesPerGroup: 1,
			breakpoints: {
				320: {
					spaceBetween: 25,
				},
				640: {
					spaceBetween: 30,
				}
			},
			navigation: {
				nextEl: ".about-media-video__wrapper .releases-pagination .swiper-button-next",
				prevEl: ".about-media-video__wrapper .releases-pagination .swiper-button-prev",
			}
		});

		document.querySelector(".about-media-video__wrapper .releases-pagination .releases-pagination-swiper .releases-pagination__button").classList.add("active");
		var pagination = document.querySelectorAll(".about-media-video__wrapper .releases-pagination .releases-pagination__button");
		pagination.forEach(function (p) {
			p.addEventListener("click", function (e) {

				// active number page
				pagination.forEach(function (item) {
					if (item.classList.contains("active")) {
						item.classList.remove("active");
					}
				})
				p.classList.add('active');

				// get data-page
				var value = p.getAttribute("data-url");

				// Get api
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						var wrapper = document.querySelector('.about-media-video__wrapper .about-media-video__list');
						wrapper.innerHTML = this.responseText;
					}
					else {
						console.log(`not found ${value}`);
						return;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("GET", `/get-about-gallery/${value}`, true);
				xhttp.send();

				// scroll
				// var reScroll = document.getElementById("scrollToVideo");
				// reScroll.scrollIntoView();
			})
		})
	}
}

var swiperModel = new Swiper(".about-media-video__wrapper .model .video__model-swiper", {
	slidesPerView: 1,
	spaceBetween: 50,
	slidesPerGroup: 1,

	navigation: {
		nextEl: ".about-media-video__wrapper .model .swiper-button-next",
		prevEl: ".about-media-video__wrapper .model .swiper-button-prev",
	}
});

function popupHandle() {
	if (document.querySelector('.about-media-video__wrapper')) {
		// event click model
		var popupModelVideo = document.querySelectorAll(".about-media-video__wrapper .about-media-video__item");
		popupModelVideo.forEach(function (pop) {
			pop.addEventListener('click', function () {
				var model = document.getElementById('model-video');
				if (model) {
					if (!model.classList.contains('active')) {
						model.classList.add('active');
						new Swiper(".about-media-video__wrapper .model .video__model-swiper", {
							slidesPerView: 1,
							spaceBetween: 50,
							slidesPerGroup: 1,

							navigation: {
								nextEl: ".about-media-video__wrapper .model .swiper-button-next",
								prevEl: ".about-media-video__wrapper .model .swiper-button-prev",
							}
						});
					}
				}

				var dataModel = pop.getAttribute('data-model');
				// Get api
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						var modelList = document.querySelector('.about-media-video__wrapper .about-media-video .model .swiper-wrapper');
						modelList.innerHTML = this.responseText;

						new Swiper(".about-media-video__wrapper .model .video__model-swiper", {
							slidesPerView: 1,
							spaceBetween: 50,
							slidesPerGroup: 1,

							navigation: {
								nextEl: ".about-media-video__wrapper .model .swiper-button-next",
								prevEl: ".about-media-video__wrapper .model .swiper-button-prev",
							}
						});
					}
					else {
						console.log(`not found model ${dataModel}`);
						return;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("GET", `/get-about-gallery-popup/${dataModel}`, true);
				xhttp.send();

				// .video__model-item.swiper-slide
				//             img.video__model-item--img(src="./assets/images/about/default-partner.png" alt="partner")
				//             h5.video__model-item--title Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi, quam.
			})
		})
		// event closed in model
		var closedPopupModelVideo = document.querySelector('.model-closed');
		closedPopupModelVideo.addEventListener('click', function () {
			var model = document.getElementById('model-video');
			if (model) {
				if (model.classList.contains('active')) {
					model.classList.remove('active');
				}
			}
		})
	}
}

var swiperAboutBlog = function () {
	if (!document.querySelector('.about-story-blog__wrapper')) {
		return;
	}
	// about blog box
	var swiperVideo = new Swiper(".about-story-blog__wrapper .about-story-blog .releases-pagination-swiper", {
		slidesPerView: 4,
		spaceBetween: 30,
		slidesPerGroup: 1,
		breakpoints: {
			320: {
				spaceBetween: 25,
			},
			640: {
				spaceBetween: 30,
			}
		},
		navigation: {
			nextEl: ".about-story-blog__wrapper .about-story-blog .swiper-button-next",
			prevEl: ".about-story-blog__wrapper .about-story-blog .swiper-button-prev",
		}
	});

	document.querySelector(".about-story-blog__wrapper .releases-pagination .releases-pagination-swiper .releases-pagination__button").classList.add("active");
	var pagination = document.querySelectorAll(".about-story-blog__wrapper .releases-pagination .releases-pagination__button");
	pagination.forEach(function (p) {
		p.addEventListener("click", function (e) {

			// active number page
			pagination.forEach(function (item) {
				if (item.classList.contains("active")) {
					item.classList.remove("active");
				}
			})
			p.classList.add('active');

			// get data-page
			var value = p.getAttribute("data-url");

			// Get api
			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function () {
				if (this.readyState == 4 && this.status == 200) {
					var wrapper = document.querySelector('.faq-content__wrapper .faq-content .faq-left__items');
					wrapper.innerHTML = this.responseText;
				}
				else {
					console.log(`not found ${value}`);
					return;
				}
			};
			// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
			xhttp.open("GET", `/get-about-blog/${value}`, true);
			xhttp.send();

			// scroll
			// var reScroll = document.getElementById("scrollToBlog");
			// reScroll.scrollIntoView();
		})
	})
}

function validateEmail(email: string) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

var submitDetailBlog = function () {
	if (document.querySelector('.blog-detail__wrapper')) {

		var formShare = document.getElementById('share-detail-form');
		var emailInput = (<HTMLInputElement>document.getElementById('emailShare'));
		var info = document.getElementById('shareFormInfo');
		emailInput.addEventListener('change', function (e) {
			info.innerHTML = "";
			if (info.classList.contains('success')) {
				info.classList.remove('success');
			}
			else if (info.classList.contains('error')) {
				info.classList.remove('error');
			}
		})

		formShare.addEventListener('submit', function (e) {
			e.preventDefault();
			var email = emailInput.value;
			if (validateEmail(email)) {
				// Get api
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {

					if (this.readyState == 4 && this.status == 200) {
						info.classList.add('success');
						info.innerHTML = this.responseText;
					}
					else {
						info.classList.add('error');
						info.innerHTML = this.responseText;
						return;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("POST", `/post-detail-blog`, true);
				xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
				xhttp.send(`email=${email}`);
			}
			else {
				var data = emailInput.getAttribute('data-info');
				info.classList.add('error');
				info.innerHTML = data;
			}
		})
	}
}

var swiperDealerProduct = function () {
	if (document.querySelector('.dealer-product__wrapper')) {
		// about blog box
		var swiperDL = new Swiper(".dealer-product__wrapper .dealer-product .releases-pagination-swiper", {
			slidesPerView: 4,
			spaceBetween: 30,
			slidesPerGroup: 1,

			navigation: {
				nextEl: ".dealer-product__wrapper .dealer-product .swiper-button-next",
				prevEl: ".dealer-product__wrapper .dealer-product .swiper-button-prev",
			}
		});
		var pagination = document.querySelectorAll('.dealer-product__wrapper .dealer-product .releases-pagination__button');
		pagination.forEach(function (item) {
			item.addEventListener('click', function () {
				// active number page
				pagination.forEach(function (e) {
					e.classList.remove('active');
				})
				item.classList.add('active');

				var value = item.getAttribute('data-url');

				// Get api
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						var wrapper = document.querySelector('.dealer-product__wrapper .dealer-product .dealer-product-list');
						wrapper.innerHTML = this.responseText;
					}
					else {
						console.log(`not found dealer product ${value}`);
						return;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("GET", `/get-dealer-product/${value}`, true);
				xhttp.send();
			})
		})


		// scroll
		var reScroll = document.getElementById("scrollToVideo");
		reScroll.scrollIntoView();
	}
}

function fMattressCollection() {
	if (document.querySelector('.mattress-collections__wrapper')) {
		var options = document.querySelectorAll('.collections-section .options');
		options.forEach(function (option) {
			option.addEventListener('click', function (e) {
				e.preventDefault();
				if (!option.classList.contains('active')) {
					options.forEach(function (o) {
						o.classList.remove('active');
					})
					option.classList.add('active');
				}
				// Get api
				var value = option.getAttribute('data-option');
				var xhttp = new XMLHttpRequest();
				xhttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						var wrapper = document.querySelector('.mattress-collections__wrapper .mattress-collections .collections__products');
						wrapper.innerHTML = this.responseText;
					}
					else {
						console.log(`not found explore collection ${value}`);
						return;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp.open("GET", `/get-explore-collection/${value}`, true);
				xhttp.send();

				// Get api
				var xhttp2 = new XMLHttpRequest();
				xhttp2.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200) {
						var wrapper = document.querySelector('.mattress-recommend__wrapper > .container');
						wrapper.innerHTML = this.responseText;

						var recommends = document.querySelectorAll('.options-recommend .options-recommend__link');
						recommends.forEach(function (recommend) {
							recommend.addEventListener('click', function (e) {
								e.preventDefault();
								if (!recommend.classList.contains('active')) {
									recommends.forEach(function (item) {
										item.classList.remove('active');
									})
									recommend.classList.add('active');

									// Get api
									var value = recommend.getAttribute('data-recommend');
									var xhttp = new XMLHttpRequest();
									xhttp.onreadystatechange = function () {
										if (this.readyState == 4 && this.status == 200) {
											var wrapper = document.querySelector('.mattress-recommend__wrapper .mattress-recommend');
											wrapper.innerHTML = this.responseText;

											new Swiper('.mattress-recommend__wrapper .right-recommend .swiper-container', {
												slidesPerView: 1,

												loop: true,
												navigation: {
													nextEl: ".swiper-button-next",
													prevEl: ".swiper-button-prev",
												},
											});
										}
										else {
											console.log(`not found recommend collection ${value}`);
											return;
										}
									};
									// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
									xhttp.open("GET", `/get-recommend-collection/${value}`, true);
									xhttp.send();
								}
							})
						})

					}
					else {
						console.log(`not found explore collection2 - ${value}`);
						return;
					}
				};
				// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
				xhttp2.open("GET", `/get-explore-collection-2/${value}`, true);
				xhttp2.send();
			})
		})
	}

	if (document.querySelector('.mattress-recommend__wrapper')) {
		var recommends = document.querySelectorAll('.options-recommend .options-recommend__link');
		recommends.forEach(function (recommend) {
			recommend.addEventListener('click', function (e) {
				e.preventDefault();
				if (!recommend.classList.contains('active')) {
					recommends.forEach(function (item) {
						item.classList.remove('active');
					})
					recommend.classList.add('active');

					// Get api
					var value = recommend.getAttribute('data-recommend');
					var xhttp = new XMLHttpRequest();
					xhttp.onreadystatechange = function () {
						if (this.readyState == 4 && this.status == 200) {
							var wrapper = document.querySelector('.mattress-recommend__wrapper .mattress-recommend');
							wrapper.innerHTML = this.responseText;

							new Swiper('.mattress-recommend__wrapper .right-recommend .swiper-container', {
								slidesPerView: 1,

								loop: true,
								navigation: {
									nextEl: ".swiper-button-next",
									prevEl: ".swiper-button-prev",
								},
							});
						}
						else {
							console.log(`not found recommend collection ${value}`);
							return;
						}
					};
					// xhttp.open("GET", "https://jsonplaceholder.typicode.com/posts", true);
					xhttp.open("GET", `/get-recommend-collection/${value}`, true);
					xhttp.send();
				}
			})
		})
	}
}

function preventDefalutLink() {
	var link = document.querySelector('.advertise-pic > a');
	if (link) {
		link.addEventListener('click', function (e) {
			e.preventDefault();
		})
	}
}

function scrollMattress(){
	if (document.querySelector('.featured')){
		var items = document.querySelectorAll('.title-mattress-type .menu__item');
		if (items){
			items.forEach(function(item){
				item.addEventListener('click', function(e){
					e.preventDefault();
					var hrefItem = item.getAttribute('href');
					var featuredItem = <HTMLElement>document.querySelector(`${hrefItem}`);
					var hHeader = document.querySelector('.header_wapper');
					var topFeatured = 0;
					if (featuredItem){
						var parentFeatured = <HTMLElement> featuredItem.offsetParent;
						var count = 0;
						while(parentFeatured.nodeName !== "BODY" && count < 100){
							topFeatured += parentFeatured.offsetTop;
							parentFeatured = <HTMLElement> parentFeatured.offsetParent;
							count++;
						}
						topFeatured += featuredItem.offsetTop;
					}

					// Trừ đi thanh Header
					topFeatured -= hHeader.clientHeight;

					if (featuredItem.clientHeight < (window.innerHeight - hHeader.clientHeight))
					{
						// Top: 50%, translateY(-50%)
						topFeatured -= (window.innerHeight / 2);
						topFeatured += (featuredItem.clientHeight / 2);
					}
					
					window.scroll({
						top: topFeatured,
						left: 0,
						behavior: 'smooth'
					});
				})
			})
		}
	}
}

// import { AOS } from 'aos';

function handleGlobal(){
	var global = <HTMLElement> document.querySelector('.about-story-global__img');
	if (global){
		var parentGlobal = <HTMLElement> global.offsetParent;
		var count = 0;
		var pos = global.offsetTop;
		while(parentGlobal.nodeName !== "BODY" && count < 100){
			pos += parentGlobal.offsetTop;
			parentGlobal = <HTMLElement> parentGlobal.offsetParent;
			count++;
		}
		var hHeader = document.querySelector('.header_wapper');
		
		var posHeightGlobal = pos;
		pos -= hHeader.clientHeight;

		if (global.clientHeight < (window.innerHeight - hHeader.clientHeight))
		{
			posHeightGlobal -= (window.innerHeight - hHeader.clientHeight);
			posHeightGlobal += (global.clientHeight);

			// Top: 50%, translateY(-50%)
			pos -= (window.innerHeight / 2);
			pos += (global.clientHeight / 2);
		}
		var listIcon = document.querySelectorAll('.about-story-global__img--icon');
		
		window.addEventListener('scroll', function(e){
			console.log(window.scrollY + ' - ' + pos);
			if (window.scrollY >= pos){
				listIcon.forEach(function(icon){
					icon.classList.add('animate__animated');
					icon.classList.add('animate__bounce');
				})
			}
			else if (window.scrollY < posHeightGlobal){
				listIcon.forEach(function(icon){
					icon.classList.remove('animate__animated');
					icon.classList.remove('animate__bounce');
				})
			}
		})

		// window.scroll({
		// 	top: posHeightGlobal,
		// 	left: 0,
		// 	behavior: 'smooth'
		// });
		// console.log(global);
		// .animate__animated.animate__bounce
	}
}

document.addEventListener('DOMContentLoaded', () => {
	ourTechSwiper();
	swiperDetailProducts();
	swiperWholesaleService();
	// AOS.init();
	handleGlobal();
	fBtnArrow();
	headerUserHandle();
	footerHandle();
	// showSubMenuMobile();
	showContentDetails();
	endCheck();
	clickPaginationReleases();
	clickFancybox();
	popupHandle();
	// showContentDetails();
	swiperAboutBlog();
	toggleinput();
	submitDetailBlog();
	swiperDealerProduct();
	// popupHandle();
	navSelect();
	// ChangeButtonSwiper();

	activeIconFeatures();
	fMattressCollection();
	fDetailProducts();
	// fLoadSwiperWarranty();
	preventDefalutLink();
	submitSearch();
	scrollMattress();

})